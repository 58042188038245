import React, { memo, useEffect, useState } from "react";
import {
    AppBar,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    useScrollTrigger
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "hooks/AuthProvider";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { ProfileDataInterface } from "../_utils/interface";
import { BoltIcon } from "@heroicons/react/24/solid";
import IdleTimerComponent from "./idle-timer";
const drawerWidth = 240;

export interface NavItem {
    title: string,
    redirectUrl: string
}

const NAVITEMS: NavItem[] = [
    { title: 'Registered Batches', redirectUrl: "registered-batches" },
    { title: 'Assessment Links', redirectUrl: "assessment" },
];

export default memo(function Navbar() {
    const userAuth = useAuth();
    const location = useLocation()
    const [mobileOpen, setMobileOpen] = useState(false);
    const [uid, setUid] = useState('');
    const [profile, setProfile] = useState<ProfileDataInterface>(userAuth.user);
    const [navItems, setNavItems] = useState<NavItem[]>(NAVITEMS);
    useEffect(() => {
        let userData = userAuth.getUserInfo();
        if (userData) {
            setProfile(userData);
            if (userData?.user?.is_staff) {
                let temp = [...navItems];
                temp.push({ title: 'Manage Batch', redirectUrl: 'manage/batch' });
                setNavItems(temp);
            }
        }
    }, []);
    useEffect(() => {
        setTimeout(() => {
            const uid = userAuth.getClientUid()
            setUid(uid)
            if (uid) {
                const updatedNavItems = [
                    { title: 'Registered Batches', redirectUrl: `registered-batches` },
                    { title: 'Assessment Links', redirectUrl: `assessment` },
                ];
                setNavItems(updatedNavItems);

            }
        }, 1000)

    }, [])

    const navigate = useNavigate();
    const handleDrawerToggle = () => {
        setMobileOpen((isOpen) => !isOpen);
    };
    const trigger = useScrollTrigger({
        target: window ? window : undefined,
    });

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [showLogo, setShowLogo] = useState(false);
    useEffect(() => {
        if (location.pathname === '/') {
            setShowLogo(false);
            const timer = setTimeout(() => {
                setShowLogo(true);
            }, 2000);
            return () => clearTimeout(timer);
        } else {
            setShowLogo(true);
        }
    }, [location.pathname]);


    const drawer = (
        <Box onClick={handleDrawerToggle} className="flex justify-center items-center flex-col py-4">

            {uid ? (<>
            </>) : (<>
                {location.pathname !== '/login' && location.pathname !== '/register' && location?.pathname !== '/login/capabl' && location?.pathname !== '/registerd-batches' &&
                    <>
                        {showLogo && (
                            <Link to={'/'}>
                                <img className="max-w-[170px]" src="/Coding Judge Logo.webp" alt="CodingJudge Logo" />
                            </Link>
                        )}
                    </>
                }

            </>)
            }

            < Divider className="w-full" />
            <List className="w-full">
                {navItems.map((item: NavItem, index) => (
                    <ListItem className={'w-full'} key={index} disablePadding>
                        <Link className={'w-full'} to={`/${item.redirectUrl}`}>
                            <ListItemButton className="text-center !font-700">
                                <ListItemText primary={item.title} className="!font-700" />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                ))}
            </List>
            <Divider className="w-full" />
            <List className="w-full">
                {userAuth.token ? <>
                    <ListItem className={'w-full'} disablePadding>
                        <ListItemButton onClick={() => userAuth.logOut()} className={'w-full'}
                            sx={{ textAlign: 'center' }}>
                            <ListItemText className="normal-case text-primary-500">Logout</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem className={'w-full'} disablePadding>
                        <ListItemButton className={'w-full'} sx={{ textAlign: 'center' }}>
                            <Link className="w-full" to="/account/change-password"><ListItemText
                                className="normal-case text-primary-500">Change Password</ListItemText></Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem className={'w-full'} disablePadding>
                        <ListItemButton className={'w-full'} sx={{ textAlign: 'center' }}>
                            <Link className="w-full" to="/account/profile"><ListItemText
                                className="normal-case text-primary-500">Edit Profile</ListItemText></Link>
                        </ListItemButton>
                    </ListItem>
                </> : <>
                    <ListItem className={'w-full'} disablePadding>
                        <ListItemButton className={'w-full'} sx={{ textAlign: 'center' }}>
                            <Link className="w-full" to="/login"><ListItemText
                                className="normal-case text-primary-500">Login</ListItemText></Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem className={'w-full'} disablePadding>
                        <ListItemButton className={'w-full'} sx={{ textAlign: 'center' }}>
                            <Link className="w-full" to="/register"><ListItemText
                                className="normal-case text-primary-500">Sign Up</ListItemText></Link>
                        </ListItemButton>
                    </ListItem>
                </>}
            </List>
        </Box >
    );

    return <>
        <Box sx={{ display: `${userAuth.showHeader ? 'flex' : 'none'}` }}>
            <IdleTimerComponent />
            <AppBar variant="elevation" elevation={!trigger ? 0 : 10}
                color={'transparent'}>
                <Toolbar className="!text-[#222] justify-between w-svw bg-white border-0 py-3 px-2 md:py-5 md:px-4">
                    <div className="flex gap-2 items-center">
                        {uid ? (
                            <>
                                {userAuth?.clientLogo?.length > 0 ? <img className="max-w-[160px] h-9" src={userAuth?.clientLogo} alt="Client Logo" /> : null}
                            </>

                        ) :
                            <>
                                {location.pathname !== '/login' && location.pathname !== '/register' && location?.pathname !== '/login/capabl' && location?.pathname !== '/registerd-batches' &&
                                    <>
                                        {showLogo && (
                                            <Link to={'/'}>
                                                <img className="max-w-[170px]" src="/Coding Judge Logo.webp" alt="CodingJudge Logo" />
                                            </Link>
                                        )}
                                    </>
                                }

                            </>


                        }

                        {/* {userAuth?.clientLogo?.length > 0 ? <img className="max-w-[160px] h-9" src={userAuth?.clientLogo} alt="Client Logo" /> : null} */}
                    </div>

                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <div className="flex gap-5">
                            {navItems.map((item, index) => (
                                <Link to={`/${item.redirectUrl}`}
                                    className="normal-case !font-700 text-[#222] p-2 hover:text-primary-500" key={item.title}>
                                    {item.title}
                                </Link>
                            ))}
                        </div>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                        {userAuth.token ? <>
                            <span className="inline-flex items-center justify-center p-1 mt-3 gap-2 px-2 text-secondary-500 text-xs h-6 w-auto bg-[#FFF8E0] rounded-[29px] text-secondary-500 me-2 border border-[#f9cd0c]">
                                <BoltIcon className="h-4 w-4 text-[#f9cd0c]" />
                                {profile?.streak}
                            </span>

                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                className="!normal-case !text-gray-500"
                            >
                                <div className="flex items-center gap-3 me-4">
                                    <img src='/Profile.webp' alt="Profile Picture" className="w-8 h-8 object-cover rounded-full" />
                                    <span>{profile?.user?.name ?? profile?.user?.username}</span>
                                </div>
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => {
                                    navigate("/account/profile");
                                    handleClose();
                                }}>
                                    <ListItemIcon>
                                        <AccountCircleOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Profile
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    navigate("/account/change-password");
                                    handleClose();
                                }}>
                                    <ListItemIcon>
                                        <LockResetOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Change Password
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    userAuth.logOut();
                                    handleClose();
                                }}>
                                    <ListItemIcon>
                                        <LogoutOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Logout
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </> :
                            <>
                                <div className="flex gap-4">
                                    <Link to="/login"><Button
                                        className="!normal-case !border-primary-500 !text-primary-500"
                                        variant={'outlined'}>Login</Button></Link>
                                    <Link to="/register"><Button className="!normal-case !bg-primary-500"
                                        variant={'contained'}>Sign Up</Button></Link>
                                </div>
                            </>}

                    </Box>

                    <IconButton
                        className="px-3 rounded-full py-1"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Box>
                <Toolbar />
                <Toolbar />
            </Box>
        </Box >
    </>
})