import { Box } from "@mui/material";
import { useAuth } from "hooks/AuthProvider";
export default function Footer() {
    const userAuth = useAuth();
    return <>
        {userAuth.clientUid ? null : <>
            < Box sx={{ display: `${userAuth.showHeader ? '' : 'none'}` }}>
                <hr />
                <footer className="text-center py-8 font-light">
                    Copyright &#169; CodingJudge
                </footer>
            </Box >
        </>

        }


    </>;
}